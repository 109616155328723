//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------
$primary-font: "Montserrat";
$secondary-font: "Poppins";
$highlight-blue: #1181E8;
$radius-30: 30px;
$radius-40: 40px;

body {
    font-family: $primary-font;
}

.bg-dark{
   background: #2C2D33;
   padding-top: 150px;
    padding-bottom: 150px;
}
.hero-btn{
    background: $highlight-blue;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color:#fff !important;
    border: none;
    border-radius: $radius-30;
    width:300px;
    height: 62px;
    padding: 5px;
    display: inline-block;
    text-align: center;
    font-style: none;
    text-decoration: none;
    background-image: url(../../dist/images/Section1/phone-icon.png);
    background-repeat: no-repeat;
    background-position: 8.32% center;
}
.hero-btn:hover{
    background-color: #00aa46;
}
#hero-image{
    border-radius: $radius-40;
    position: relative;
}
#hero-image-holder{
    position: relative;
}
#hero-image-holder::before{
    content: '';
    background: url(../../dist/images/Section1/white-dots-top-of-car.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    right: 10px;
    top: -90px;
}
#hero-image-holder::after{
    content: '';
    background: url(../../dist/images/Section1/dots-left-of-car.png);
    width: 68.8px;
    height: 187.97px;
    position: absolute;
    left: -80px;
    bottom: 0;
}
#hero-h1{
    font-family: $primary-font;
    font-weight: 600;
    font-size: 60px;
    line-height: 73px;
    position: relative;
}
#hero-h1::before{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    left: 0px;
    top: -100px;
}
#hero-content{
    font-family: $primary-font;
    font-size: 20px;
    line-height: 24px;
}
#highlights-section{
    position: relative;
    background: #fff;
    width:80%;
    max-width: 1320px;
    margin:0 auto;
    margin-top: -50px;
    margin-bottom: 100px;
    border-radius: $radius-40;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    padding: 50px;
}
.highlight-section-content{
    font-family: $primary-font;
    color: #000;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
}
.highlight-link{
    color: $highlight-blue;
    text-decoration: none;
}
.main-content-section-heading{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 85px;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}
.main-content-section-subheading{
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
}
.regular-content{
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: justify;
}
.frame-image{
    width: 100%;
}
.main-content-section-image-holder{
    position: relative;
}
.main-content-section-image-holder::before{
    content: '';
    background: url(../../dist/images/Section1/dot-pattern-top-of-the-photo.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 242.38px;
    height: 242.38px;
    position: absolute;
    left: -30px;
    top: -30px;
    overflow: hidden;
}
.main-content-section-image-holder::after{
    content: '';
    background: url(../../dist/images/Section1/dot-pattern-bottom-of-the-photo.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 168.68px;
    height: 242.38px;
    position: absolute;
    right: -30px;
    bottom: -30px;
    overflow: hidden;
    pointer-events: none;
}
.image-button{
    position: absolute;
    left: 5%;
    bottom: 5%;
    background: $highlight-blue;
    border-radius: $radius-30;
    font-family: $primary-font;
    font-weight: 600;
    width: 159px;
    padding: 10px 0px 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    background-image: url(../../dist/images/Section1/phone-icon.png);
    background-repeat: no-repeat;
    background-position: 9% center;
    background-size: 25px;
}
.image-button:hover{
    background-color: #00aa46;
}
/*Section Two*/
#main-content-section-two, #main-content-section-four{
    position: relative;
    background-color: #F1F1F1;
}
#main-content-section-two::before{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    left: 0px;
    top: calc(50% - 34.4px);
    pointer-events: none;
}
#main-content-section-two::after{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    right: 0px;
    top: calc(50% - 34.4px);
    pointer-events: none;
}
#main-content-section-four::before{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    left: 0px;
    top: 55px;
    pointer-events: none;
}
#main-content-section-four::after{
    content: '';
    background: url(../../dist/images/Section1/blue-dots.png);
    width: 187.97px;
    height: 68.8px;
    position: absolute;
    right: 0px;
    top: 55px;
    pointer-events: none;
}
#main-content-section-two .main-content-section-heading, #main-content-section-four .main-content-section-heading{
    color: #F1F1F1;
}
.list-style li{
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    padding: 15px 0px;
}
.image-button-2{
    position: absolute;
    left: 5%;
    top: 5%;
    background: $highlight-blue;
    border-radius: $radius-30;
    font-family: $primary-font;
    font-weight: 600;
    width: 159px;
    padding: 10px 0px 10px 15px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    line-height: 22px;
    background-image: url(../../dist/images/Section1/phone-icon.png);
    background-repeat: no-repeat;
    background-position: 9% center;
    background-size: 25px;
}
.image-button-2:hover{
    background-color: #00aa46;
}
.review-author{
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 37px;
}
.review-content{
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}
.review-star-rating img{
    width: 30px;
}
#main-content-section-five{
    position: relative;
    background: #fff;
    width:80%;
    max-width: 1320px;
    margin:0 auto;
    margin-bottom: 100px;
    margin-top:100px;
    border-radius: $radius-40;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25);
    padding: 50px;
}
.section-five-heading{
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 49px;
    margin-bottom: 30px;
}
.footer-menu{
    list-style: none;
}
.footer-menu li{
    display: inline-block;
    margin-right: 20px;
    font-family: $primary-font;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
}
@media only screen and (max-width: 1430px) {
    #main-content-section-four::before, #main-content-section-four::after{
        display: none;
    }
  }
  @media only screen and (max-width: 992px) {
    #main-content-section-two::before, #main-content-section-two::after, #hero-image-holder::before{
        display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    #hero-image-holder::after{
        display: none;
    }
  }
  @media only screen and (max-width: 600px) {
    .main-content-section-image-holder::before, .main-content-section-image-holder::after{
        display: none;
    }
  }
//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";